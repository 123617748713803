export type EmailProviderSendQuota = {
    max24HourSend: number;
    sentLast24Hours: number;
    maxSendRate: number;
};

export type EmailProviderSendStatisticsDTO = {
    bounces: number;
    complaints: number;
    deliveryAttempts: number;
    rejects: number;
    timestamp: string;
};

export class EmailProviderSendStatistics {
    bounces: number;
    complaints: number;
    deliveryAttempts: number;
    rejects: number;
    timestamp: Date;

    constructor(bounces: number, complaints: number, deliveryAttempts: number, rejects: number, timestamp: Date) {
        this.bounces = bounces;
        this.complaints = complaints;
        this.deliveryAttempts = deliveryAttempts;
        this.rejects = rejects;
        this.timestamp = timestamp;
    }

    static createFromApi(dto: EmailProviderSendStatisticsDTO) {
        return new EmailProviderSendStatistics(
            dto.bounces,
            dto.complaints,
            dto.deliveryAttempts,
            dto.rejects,
            new Date(dto.timestamp)
        );
    }

    static generateFullTimeline = (data: EmailProviderSendStatistics[]) => {
        data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

        const start = data[0].timestamp;
        const end = new Date();
        const interval = 15 * 60 * 1000; // 15 minutes in milliseconds.

        const fullTimeline = [];
        for (let ts = start; ts <= end; ts = new Date(ts.getTime() + interval)) {
            const timestamp = ts;
            const point = data.find(d => d.timestamp.getTime() === timestamp.getTime()) || {
                deliveryAttempts: 0,
                timestamp,
                bounces: 0,
                complaints: 0,
                rejects: 0,
            };
            fullTimeline.push(point);
        }
        return fullTimeline;
    };
}

export type EmailProviderVerifiedIdentity = {
    identity: string;
    verificationStatus: 'Pending' | 'Success' | 'Failed' | 'TemporaryFailure' | 'NotStarted';
};


export type ReputationMetricDataPointDTO = {
    timestamp: string;
    value: number;
};

export class ReputationMetricDataPoint {
    timestamp: Date;
    //**  0.06029157768664313
    value: number;

    constructor(timestamp: Date, value: number) {
        this.timestamp = timestamp;
        this.value = value;
    }

    static createFromApi(dto: ReputationMetricDataPointDTO) {
        return new ReputationMetricDataPoint(new Date(dto.timestamp), dto.value);
    }
}
