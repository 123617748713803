export class UserExportJob {
    type: string;
    status: string;
    format: string;
    fields: {
        name: string;
    }[];
    created_at: Date;
    id: string;
    location: string;

    constructor(dto: UserExportJobDTO) {
        this.type = dto.type;
        this.status = dto.status;
        this.format = dto.format;
        this.fields = dto.fields;
        this.created_at = new Date(dto.created_at);
        this.id = dto.id;
        this.location = dto.location;
    }

    static createFromApi(dto: UserExportJobDTO): UserExportJob {
        return new UserExportJob(dto);
    }
};

export type UserExportJobDTO = {
    type: string;
    status: string;
    format: string;
    fields: {
        name: string;
    }[];
    created_at: string;
    id: string;
    location: string;
};

export type UserExportListingDTO = {
    jobId: string;
    created: string;
    isExpired: boolean;
    requestedById: number;
    requestedByEmail: string;
};

export class UserExportListing {
    jobId: string;
    created: Date;
    isExpired: boolean;
    requestedById: number;
    requestedByEmail: string;

    constructor(dto: UserExportListingDTO) {
        this.jobId = dto.jobId;
        this.created = new Date(dto.created);
        this.isExpired = dto.isExpired;
        this.requestedById = dto.requestedById;
        this.requestedByEmail = dto.requestedByEmail;
    }

    static createFromApi(dto: UserExportListingDTO): UserExportListing {
        return new UserExportListing(dto);
    }
}